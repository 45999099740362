import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DetailsView } from './view';

export const Details = ({ data }) => {
    const initialProperties = { color: {} };
    const [properties, setProperties] = useState(initialProperties);

    const setProperty = (currentProperties) => {
        setProperties((previousProperties) => (
            Object.values(previousProperties).includes(Object.values(currentProperties)[0]) ? initialProperties : {
                ...previousProperties,
                ...currentProperties
            }
        ));
    };

    return (
        <>
            <DetailsView
                product={ data }
                properties={ properties }
                setProperty={ setProperty }
            />
        </>
    );
};

Details.propTypes = {
    data: PropTypes.string.isRequired
};