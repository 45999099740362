import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { Navigation } from 'utilities/components/navigation/component';
import { Details } from 'features/accessory/details/component';
import { Footer } from 'utilities/components/footer/component';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import SEO from 'utilities/components/seo';
import { paths, routes } from 'utilities/routes';

import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';

export const query = graphql`
    query($id: Int) {
        allAccessories(filter: { alternative_id: { eq: $id }, id: { ne: "dummy" } }) {
            nodes {
                bestseller
                alternative_id
                image
                producer_logo
                name
                description
                price
                brands {
                    name
                    type
                }
                color_gallery {
                    color_id
                    color_image
                }
                color_list {
                    alternative_id
                    name
                    image
                    code
                    show_color_type
                }
            }
        }
    }
`;

const Accessory = ({ data }) => {
    const productBreadcrubsObject = {
        path: `${routes.produkt.path}/${data?.allAccessories?.nodes[0]?.alternative_id}`,
        name: data?.allAccessories?.nodes[0]?.name
    };

    const breadcrumbs = [routes.home, routes.klamki, productBreadcrubsObject];

    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };

    return (
        <main className="product">
            <SEO
                title={ `Barański Drzwi - Produkt - ${data?.allAccessories?.nodes[0]?.name}` }
                description={ `Wejdź na stronę i poznaj naszą ofertę. Poznaj markę Barański i dowiedz się więcej o produkcie ${data?.allAccessories?.nodes[0]?.name}` }
                image={ `${process.env.GATSBY_URL}/${data?.allAccessories?.nodes[0]?.image}` }
                alt={ data?.allAccessories?.nodes[0]?.name }
                canonicalLink={ setCanonicalLink() }
            />
            <Helmet>
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "Product",
                            "name": "${data?.allAccessories?.nodes[0]?.name}",
                            "description": "${data?.allAccessories?.nodes[0]?.description}",
                            "brand": "Barański",
                            "image": "${process.env.GATSBY_URL}/${data?.allAccessories?.nodes[0]?.image}",
                            "offers": {
                                "@type": "Offer",
                                "url": "https://www.baranskidrzwi.pl${paths.akcesorium}/${data?.allAccessories?.nodes[0]?.alternative_id}",
                                "priceCurrency": "PLN",
                                "price": "${data?.allAccessories?.nodes[0]?.price}",
                                "availability": "https://schema.org/InStock",
                                "itemCondition": "https://schema.org/NewCondition"
                            }
                        }
                    `}
                </script>
            </Helmet>
            <Navigation />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <Details data={ data?.allAccessories?.nodes[0] } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default Accessory;

Accessory.propTypes = {
    data: PropTypes.string.isRequired
};
