import React from 'react';
import PropTypes from 'prop-types';

import { Details } from 'utilities/components/product/details/component';
import { Property } from 'utilities/components/product/property/component';
import { OptionsView } from 'utilities/components/product/options/component/view';

export const DetailsView = ({ product, properties, setProperty }) => (
    <Details product={ product } properties={ properties }>
        {
            (product?.color_list?.length)
                ? (
                    <Property
                        isCollapsible={ false }
                        headline={ (
                            <>
                                Kolor:
                                { ' ' }
                                { properties?.color.name }
                            </>
                        ) }
                    >
                        <OptionsView
                            isInteractive
                            property="color"
                            data={ product?.color_list }
                            activeOption={ properties?.color.id }
                            setActiveOption={ setProperty }
                        />
                    </Property>
                )
                : (null)
        }
    </Details>
);

DetailsView.propTypes = {
    product: PropTypes.instanceOf(Object).isRequired,
    properties: PropTypes.instanceOf(Object).isRequired,
    setProperty: PropTypes.func.isRequired
};